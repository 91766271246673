import { FactSetWidget } from "./FactsetWidget";

type SymbolSearchResultClickEvent = {
    detail: [{
        identifier: string;
    }]
}
type SymbolSearchAllResultsClickEvent = {
    detail: [string]
}

export type SymbolSearchProps = {
    onSearchResultClick: (event: SymbolSearchResultClickEvent) => void;
    onAllSearchResultsClick: (event: SymbolSearchAllResultsClickEvent) => void;
};

export function SymbolSearch({ onSearchResultClick, onAllSearchResultsClick }: SymbolSearchProps) {
    return (
        <FactSetWidget
          config={[
            { type: "event", key: "all-search-results", value: onAllSearchResultsClick },
            { type: "event", key: "select-result", value: onSearchResultClick },
            { type: "attribute", key: "identifierEmitted", value: "tickerRegion" },
          ]}
          pendingElement={null} // We don't want to show a spinner when the search is pending
          errorElement={null} // We don't want to show an error message when the search fails
          widget="search-suggester"
        />
      );
}